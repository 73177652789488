import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { deactivateUsers } from '../../../containers/actions/pharmaCo/manageUsersActions';
import { useDispatch, useSelector } from 'react-redux';
import StyledButton from '../../../../common/models/Button';
import {
  DESTROY_BACKDROP,
  OPEN_BACKDROP,
  USER_DEACTIVATION,
} from '../../../containers/actions/types';
import CloseIcon from '@material-ui/icons/Close';
import BackDropLoading from '../models/BackDrop';
// useSelector,
const useStyles = makeStyles((theme) => ({
  styledBtn: {
    textTransform: 'none',
    borderRadius: '2rem',
    paddingRight: '3rem',
    paddingLeft: '3rem',
  },
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1rem 0.5rem',
    '&:hover': {
      color: '#FF0000',
    },
  },
}));

const UserDeactivation = (props) => {
  const classes = useStyles();
  const { handleConfirmation, onClose } = props;
  const [disableButton, setDisableButton] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  let userDeactivated = useSelector((state) => {
    return state.pcmp.pharmaCo.manageUsers.deactivatedUser;
  });
  useEffect(() => {
    if (userDeactivated !== undefined && disableButton === true) {
      if (userDeactivated?.status === true) {
        handleConfirmation(true);
        onClose();
        dispatch({
          type: USER_DEACTIVATION,
          payload: undefined,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      } else if (userDeactivated?.status === false) {
        setError(true);
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    }
  }, [dispatch, userDeactivated, disableButton, handleConfirmation, onClose]);
  const handleConfirm = () => {
    dispatch({
      type: OPEN_BACKDROP,
      backdropText: 'Please Wait....',
    });
    dispatch(
      deactivateUsers({
        emailAddress: props.deactivateUserData.email,
        saasUserId: props.deactivateUserData.id,
        roleId: props.deactivateUserData?.roleId,
      })
    );
    setDisableButton(true);
    // props.handleConfirmation(true)
    // props.onClose()
    // if (userDeactivated !== undefined && userDeactivated.status === true) {
    //     setDisableButton(true);
    //     props.handleConfirmation(true)
    //     props.onClose()
    // }
  };
  return (
    <Fragment>
      <BackDropLoading />
      <Grid container justifyContent='flex-end'>
        <Typography
          variant='body2'
          className={classes.closeText}
          onClick={props.onClose}
        >
          Close <CloseIcon fontSize='medium' />
        </Typography>
      </Grid>
      <Grid container justifyContent='center'>
        {error && (
          <Grid
            item
            xs={10}
            style={{
              border: '1px solid #FF0000',
              backgroundColor: '#FFF0F0',
              opacity: '1',
              padding: '1.5rem',
              fontSize: '0.875rem',
              textAlign: 'center',
              margin: '1rem',
            }}
          >
            <Typography
              variant='h6'
              style={{
                fontSize: '0.875rem',
              }}
            >
              Something went wrong. Please try again.
            </Typography>
          </Grid>
        )}

        <Grid
          item
          xs={10}
          sm={9}
          md={9}
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '1.5rem',
          }}
        >
          <Typography
            variant='body1'
            style={{ fontSize: '1.5rem', fontWeight: 500 }}
          >
            Are you sure you want to deactivate the following user?
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          sm={9}
          md={9}
          style={{
            display: 'flex',
            justifyContent: 'center',
            border: '1px solid grey',
            backgroundColor: 'whitesmoke',
            padding: '1rem',
            marginBottom: '4rem',
          }}
        >
          {props.deactivateUserData.email}
        </Grid>

        <Grid
          item
          xs={10}
          sm={9}
          md={9}
          style={{
            display: 'flex',
            justifyContent: 'center',
            border: '1px solid grey',
            backgroundColor: 'whitesmoke',
            padding: '2.5rem',
            marginBottom: '2rem',
          }}
        >
          Please note: Actions performed by the deactivated user will still show
          under their username.
        </Grid>

        <Grid
          item
          xs={6}
          sm={4}
          md={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1.5rem',
          }}
        >
          {/* <Button
                        variant='contained'
                        color='primary'
                        disableElevation
                        disableRipple
                        disableFocusRipple
                        className={classes.styledBtn}
                        onClick={handleConfirm}
                        disabled={disableButton}
                    >Yes</Button> */}

          <StyledButton
            className={classes.styledBtn}
            onClick={handleConfirm}
            disabled={disableButton}
          >
            Yes
          </StyledButton>
        </Grid>

        <Grid
          item
          xs={6}
          sm={4}
          md={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1.5rem',
          }}
        >
          {/* <Button
                        variant='contained'
                        color='primary'
                        disableElevation
                        disableRipple
                        disableFocusRipple
                        onClick={props.onClose}
                        disabled={disableButton}
                        // style={{ backgroundColor: 'green' }}
                        className={classes.styledBtn}>No</Button> */}

          <StyledButton
            className={classes.styledBtn}
            onClick={props.onClose}
            disabled={disableButton}
          >
            No
          </StyledButton>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UserDeactivation;
