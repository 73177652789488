import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../updateBatchStyles';
import {
  presentFormatedData,
  presentDate,
} from '../../../../functionalComponents/dataFormatFunctions';
import MandatorySpan from '../../../models/MandatorySpan';
import DropFilesAttach from '../../../../models/files/DropFilesAttach';
import ShowFiles from '../../../../models/files/ShowFiles';
import { useDispatch, useSelector } from 'react-redux';
import { getAllConfigurationFlags } from '../../../../../../containers/actions/common/reportsActions';
import InputNumber from '../../../../models/inputs/InputNumber';
import CustomDatePickerMui from '../../../../models/inputs/DateInput';
import moment from 'moment';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import FileViewer from '../FileViewer';
import ShowCoAFiles from '../../../../models/files/ShowCoAFiles';

const QualityAssuranceInformation = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { collabType } = localStorage;
  const {
    batchDetails,
    errors,
    handleDateChange,
    handleChange,
    handleUploadFiles,
    newFiles,
    existingFiles,
    handleDelete,
    handleDownloadFile,
    quickView,
    coaDownloadedCheck,
    handleCoACheck,
    qaApproval,
    uploadEInvoiceFlag,
  } = props;

  const [open, setOpen] = useState(false);
  const [fileId, setFileId] = useState(null);

  // FG features and validation flags
  const QAValidations = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.validations.FG
        .DSBatch.QAInfo;
    }
    return {};
  });

  const qualityApprovalFlowFlag = useSelector((state) => {
    return state.pcmp.common.reports.rmpmGrnFeatures?.data?.features
      .qualityApprovalEnabled;
  });

  const getFeatures = useCallback(() => {
    if (Object.keys(QAValidations).length === 0) {
      dispatch(getAllConfigurationFlags());
    }
  }, [dispatch, QAValidations]);
  useEffect(() => {
    getFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFileFG = useSelector(
    (state) =>
      state.pcmp.common.accessControl.featuresAccess.fgBatchFeatures
        ?.fgBatchUploadDocuments
  );
  const isEditable = (type) => {
    let edit;
    switch (type) {
      case 'controlSampleQuantity':
        if (
          !quickView &&
          collabType === 'CMO' &&
          (batchDetails.SASLBatchStatusID === 5 ||
            batchDetails.SASLBatchStatusID === 13 ||
            batchDetails.SASLBatchStatusID === 15)
        ) {
          edit = true;
        }
        break;
      case 'COAUpload':
        if (
          qualityApprovalFlowFlag &&
          !quickView &&
          collabType === 'CMO' &&
          (([5, 6, 7, 13].includes(batchDetails.SASLBatchStatusID) &&
            (batchDetails.qaStatusID === '3' || !batchDetails.qaStatusID)) ||
            ([8, 13, 19, 20].includes(batchDetails.SASLBatchStatusID) &&
              batchDetails.qaStatusID === '3')) &&
          ((((!uploadEInvoiceFlag && batchDetails.qaStatusID === '3') ||
            batchDetails.qaStatusID !== '3') &&
            batchDetails.SASLBatchStatusID === 7) ||
            batchDetails.SASLBatchStatusID !== 7)
        ) {
          edit = true;
        } else if (
          !qualityApprovalFlowFlag &&
          !quickView &&
          collabType === 'CMO' &&
          (batchDetails.SASLBatchStatusID === 5 ||
            batchDetails.SASLBatchStatusID === 13 ||
            batchDetails.SASLBatchStatusID === 15)
        ) {
          edit = true;
        }
        break;
      case 'qcReleasedDate':
        if (
          !quickView &&
          collabType === 'CMO' &&
          (batchDetails.SASLBatchStatusID === 5 ||
            batchDetails.SASLBatchStatusID === 13 ||
            batchDetails.SASLBatchStatusID === 15)
        ) {
          edit = true;
        }
        break;
      default:
        edit = false;
    }
    return edit;
  };

  const handleViewFile = (schrockenCustomFileID) => {
    setOpen(true);
    setFileId(schrockenCustomFileID);
  };
  const handleFileViewClose = () => {
    console.log('handleFileViewClose');
    setOpen(false);
    setFileId(null);
  };

  return (
    <React.Fragment>
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12} md={12} className={classes.grid}>
          <Typography variant='h4' className={classes.title}>
            Quality Assurance Information
          </Typography>
          <span className={classes.borders}></span>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3} className={classes.grid}>
          <Typography
            variant='body2'
            className={classes.filesFont}
            style={{ fontSize: '1rem' }}
          >
            Control Sample Quantity{' '}
            {isEditable('controlSampleQuantity') && <MandatorySpan />}
          </Typography>

          {isEditable('controlSampleQuantity') ? (
            <InputNumber
              className={classes.fontPadding}
              name='controlSampleQuantity'
              inputProps={{ min: 0, step: 1 }}
              value={batchDetails.controlSampleQuantity}
              onChange={handleChange}
              helperText={errors.controlSampleQuantity}
              placeholder='Enter Control Sample Qty'
              notFullWidth
            />
          ) : (
            <Typography
              variant='body2'
              className={classes.fontPadding}
              style={{ fontSize: '1rem' }}
            >
              {presentFormatedData(
                batchDetails.controlSampleQuantity,
                batchDetails.controlSampleQuantity === 0
                  ? '0'
                  : '-Not Provided-'
              )}
            </Typography>
          )}
        </Grid>

        {/* SKU UOM */}
        <Grid item xs={12} sm={4} md={3} lg={3} className={classes.grid}>
          <Typography
            variant='body2'
            className={classes.filesFont}
            style={{ fontSize: '1rem' }}
          >
            Control Sample UOM
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            style={{ fontSize: '1rem' }}
          >
            {presentFormatedData(batchDetails.uomQA, '-Not Provided-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={3} className={classes.grid}>
          <Typography
            variant='body2'
            className={classes.filesFont}
            style={{ fontSize: '1rem' }}
          >
            QC Release Date
          </Typography>

          {isEditable('qcReleasedDate') ? (
            <CustomDatePickerMui
              value={batchDetails.qcReleasedDate}
              onChange={(date) => handleDateChange(date, 'qcReleasedDate')}
              minDate={
                batchDetails.batchEndDate !== null
                  ? batchDetails.batchEndDate
                  : new Date()
              }
              helperText={errors.qcReleasedDate}
              notFullWidth
            />
          ) : (
            <Typography
              variant='body2'
              className={classes.fontPadding}
              style={{ fontSize: '1rem' }}
            >
              {presentDate(batchDetails.qcReleasedDate, '-Not Provided-')}
            </Typography>
          )}
        </Grid>
        {/* {qualityApprovalFlowFlag && (
          <Grid item xs={12} sm={4} md={3} lg={3} className={classes.grid}>
            <Typography
              variant='body2'
              className={classes.filesFont}
              style={{ fontSize: '1rem' }}
            >
              Sponsor QA Approval Status
            </Typography>

            <Typography
              variant='body2'
              className={classes.fontPadding}
              style={{ fontSize: '1rem' }}
            >
              {presentFormatedData(batchDetails?.qaStatusText, '-NA-')}
            </Typography>
          </Grid>
        )} */}
        {(batchDetails?.qaStatusID === '2' ||
          batchDetails?.qaStatusID === '3') &&
          qualityApprovalFlowFlag && (
            <React.Fragment>
              {batchDetails?.qaStatusUpdatedBy && (
                <Grid container item xs={12}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    className={classes.grid}
                  >
                    <Typography
                      variant='body2'
                      className={classes.filesFont}
                      style={{ fontSize: '1rem' }}
                    >
                      Sponsor QA{' '}
                      {batchDetails?.qaReleaseDecisionBy && 'Manager'} Decision
                      by
                    </Typography>

                    <Typography
                      variant='body2'
                      className={classes.fontPadding}
                      style={{ fontSize: '1rem' }}
                    >
                      {presentFormatedData(
                        batchDetails?.qaStatusUpdatedBy,
                        '-NA-'
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    className={classes.grid}
                  >
                    <Typography
                      variant='body2'
                      className={classes.filesFont}
                      style={{ fontSize: '1rem' }}
                    >
                      Sponsor QA{' '}
                      {batchDetails?.qaReleaseDecisionBy && 'Manager'} Decision
                      on
                    </Typography>

                    <Typography
                      variant='body2'
                      className={classes.fontPadding}
                      style={{ fontSize: '1rem' }}
                    >
                      {moment(batchDetails?.qaStatusUpdatedDate).isValid()
                        ? moment(batchDetails?.qaStatusUpdatedDate).format(
                            'DD/MMM/YYYY  HH:mm'
                          )
                        : '-NA-'}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {batchDetails?.qaReleaseDecisionBy && (
                <Grid container item xs={12}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    className={classes.grid}
                  >
                    <Typography
                      variant='body2'
                      className={classes.filesFont}
                      style={{ fontSize: '1rem' }}
                    >
                      Sponsor QA Head Decision by
                    </Typography>

                    <Typography
                      variant='body2'
                      className={classes.fontPadding}
                      style={{ fontSize: '1rem' }}
                    >
                      {presentFormatedData(
                        batchDetails?.qaReleaseDecisionBy,
                        '-NA-'
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    className={classes.grid}
                  >
                    <Typography
                      variant='body2'
                      className={classes.filesFont}
                      style={{ fontSize: '1rem' }}
                    >
                      Sponsor QA Head Decision on
                    </Typography>

                    <Typography
                      variant='body2'
                      className={classes.fontPadding}
                      style={{ fontSize: '1rem' }}
                    >
                      {!batchDetails?.qaReleaseDecisionDate
                        ? '-NA-'
                        : moment(batchDetails?.qaReleaseDecisionDate).isValid()
                        ? moment(batchDetails?.qaReleaseDecisionDate).format(
                            'DD/MMM/YYYY  HH:mm'
                          )
                        : '-NA-'}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          )}

        <Grid item xs={12}>
          {uploadFileFG && isEditable('COAUpload') && (
            <React.Fragment>
              <DropFilesAttach
                title='Upload COA'
                handleUploadFiles={handleUploadFiles}
                required={
                  QAValidations.coaFile?.status ===
                  batchDetails.SASLBatchStatusID
                    ? QAValidations.coaFile?.required
                    : false
                }
              />
              <ShowFiles
                files={newFiles}
                clearable
                clearFile={handleDelete}
                removeNoRecordsMsg
              />
            </React.Fragment>
          )}
          {(!isEditable('COAUpload') || existingFiles.length > 0) && (
            <React.Fragment>
              <Typography
                variant='body2'
                style={{ fontWeight: 500, paddingTop: '1rem' }}
              >
                View COA files
              </Typography>
              {qualityApprovalFlowFlag ? (
                <ShowCoAFiles
                  files={existingFiles}
                  downloadable
                  downloadFile={handleDownloadFile}
                  handleViewFile={handleViewFile}
                  coaFile={true}
                  qualityApprovalFlowFlag={qualityApprovalFlowFlag}
                />
              ) : (
                <ShowFiles
                  files={existingFiles}
                  downloadable
                  downloadFile={handleDownloadFile}
                />
              )}
            </React.Fragment>
          )}
        </Grid>
        {qaApproval &&
          collabType !== 'CMO' &&
          !quickView &&
          batchDetails?.qaStatusID &&
          qualityApprovalFlowFlag && (
            <Grid
              item
              xs={12}
              style={{
                padding: '6px',
                border: '1px solid black',
                marginTop: '16px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={coaDownloadedCheck}
                    onChange={handleCoACheck}
                  />
                }
                label='I have downloaded, read, and understood the contents of CoA.'
              />
            </Grid>
          )}
      </Grid>
      <hr />
      <FileViewer
        schrockenCustomFileID={fileId}
        openFileView={open}
        handleFileViewClose={handleFileViewClose}
        downloadFile={handleDownloadFile}
      />
    </React.Fragment>
  );
};

export default QualityAssuranceInformation;
