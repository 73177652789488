import axios from 'axios';
import {
  appUrl,
  associateCMOsRoles,
  associateWarehouseRoles,
  smlUrl,
} from '../../../../../utils/config';

import {
  USERS_DETAILS,
  PCMP_COLLABORATORS,
  PCMP_ROLES,
  ADD_PCMP_USER,
  REMOVE_PCMP_USER,
  SEND_INVITES,
  USER_DEACTIVATION,
  FETCH_WAREHOUSES,
} from '../types';

export let manageuserSource;
export const usersDetails = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  manageuserSource = CancelToken.source();

  axios
    .post(`${smlUrl}/user/ecosystemOwnerManageUsersInformation`, data, {
      cancelToken: manageuserSource.token,
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: USERS_DETAILS,
          payload: res.data.members,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoUsersDetails = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/user/manageUsersInformation`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: USERS_DETAILS,
          payload: res.data.members,
        });
      }
    })
    .catch((err) => console.log(err));
};

// Fetch All Collaborators
export const getPcmpCollaborators = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/getCollaboratorTypes`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PCMP_COLLABORATORS,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// Fetch Role of Collaborator
export const getPcmpRoles = (collaboratorId) => (dispatch) => {
  axios
    .post(`${smlUrl}/getCollaboratorRoles`, collaboratorId)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PCMP_ROLES,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addPcmpUser = (inviteUser) => (dispatch) => {
  axios
    .post(`${smlUrl}/addDiffInvites`, inviteUser)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ADD_PCMP_USER,
          payload: { status: true, user: res.data.data },
        });
      } else if (res.data.status === false) {
        dispatch({
          type: ADD_PCMP_USER,
          payload: { status: false, message: res.data.message },
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch({
          type: ADD_PCMP_USER,
          payload: err.response.data,
        });
      }
    });
};

export const removePcmpUser = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/removeInvites`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: REMOVE_PCMP_USER,
          payload: res.data.emailAddress,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// Invite users to ecosystem
export const invitePcmpUsers = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/sendDiffEmailInvites`, data)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: SEND_INVITES,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const deactivateUsers = (data) => (dispatch) => {
  console.log(
    associateCMOsRoles.concat(associateWarehouseRoles).includes(data.roleId)
  );
  if (
    associateCMOsRoles.concat(associateWarehouseRoles).includes(data.roleId)
  ) {
    console.log('deactivate user associations');
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/deactivateUserAssociation`,
        {
          saasUserID: data.saasUserId,
        }
      )
      .then((res) => {
        console.log(res.data);
        console.log('deactivating user');
        if (res.data.status === true) {
          axios
            .post(`${smlUrl}/user/deactivateUser`, data)
            .then((res1) => {
              if (res1.data.status === true) {
                dispatch({
                  type: USER_DEACTIVATION,
                  payload: res1.data,
                });
              } else {
                dispatch({
                  type: USER_DEACTIVATION,
                  payload: {
                    status: false,
                    error: 'Something went wrong. Please try again later',
                  },
                });
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch({
                type: USER_DEACTIVATION,
                payload: {
                  status: false,
                  error: 'Something went wrong. Please try again later',
                },
              });
            });
        } else {
          dispatch({
            type: USER_DEACTIVATION,
            payload: {
              status: false,
              error: 'Something went wrong. Please try again later',
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: USER_DEACTIVATION,
          payload: {
            status: false,
            error: 'Something went wrong. Please try again later',
          },
        });
      });
  } else {
    axios
      .post(`${smlUrl}/user/deactivateUser`, data)
      .then((res) => {
        if (res.data.status === true) {
          dispatch({
            type: USER_DEACTIVATION,
            payload: res.data,
          });
        } else {
          dispatch({
            type: USER_DEACTIVATION,
            payload: {
              status: false,
              error: 'Something went wrong. Please try again later',
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: USER_DEACTIVATION,
          payload: {
            status: false,
            error: 'Something went wrong. Please try again later',
          },
        });
      });
  }
};

export const fetchWarehouses = () => (dispatch) => {
  // dispatch({
  //   type: FETCH_WAREHOUSES,
  //   payload: {
  //     status: true,
  //     data: {
  //       warehouseDetailsList: [
  //         {
  //           warehouseName: 'CWH-Bhiwandi',
  //           warehouseCode: 'L903',
  //         },
  //         {
  //           warehouseName: 'CWH-Chennai',
  //           warehouseCode: 'L523',
  //         },
  //         {
  //           warehouseName: 'CWH-HYDERABAD',
  //           warehouseCode: 'L801',
  //         },
  //         {
  //           warehouseName: 'CWH-Zirakpur',
  //           warehouseCode: 'L701',
  //         },
  //       ],
  //     },
  //   },
  // });
  axios
    .get(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchWarehouseDetails`
    )
    .then((res) => {
      console.log(res.data);
      if (res.data.status === true) {
        dispatch({
          type: FETCH_WAREHOUSES,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};
