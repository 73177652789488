import React, { useEffect, useState } from 'react';
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  TextField,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import { cmoMembersList } from '../../../containers/actions/pharmaCo/purchaseOrderActions';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const AssociateCMOs = ({ selectedCMOs, handleChangeCMOList, errors }) => {
  const [accessType, setAccessType] = useState('select'); // Manage radio selection
  const dispatch = useDispatch();

  let cmoList = useSelector((state) =>
    state.pcmp.pharmaCo.purchaseOrder.cmoMemberWithId
      ? state.pcmp.pharmaCo.purchaseOrder.cmoMemberWithId
      : []
  );

  useEffect(() => {
    let { collabType, ecosystemId, organizationName, roleName } = localStorage;
    dispatch(
      cmoMembersList({
        collabType: collabType,
        roleName: roleName,
        organizationName: organizationName,
        ecosystemId: ecosystemId,
      })
    );
  }, [dispatch]);
  // Example CMO list
  // const cmoList = [
  //   {
  //     organization_name: 'Malik Life Sciences Pvt Ltd',
  //     organization_id: 9,
  //     vendorPlant: 'L731',
  //   },
  //   {
  //     organization_name: 'Zen Pharma (P) Ltd',
  //     organization_id: 9,
  //     vendorPlant: 'L728',
  //   },
  //   {
  //     organization_name: 'Zeiss Pharmaceuticals Pvt. Ltd.',
  //     organization_id: 9,
  //   },
  //   {
  //     organization_name: 'Zeiss Pharma Limited (Unit-I)',
  //     organization_id: 9,
  //   },
  //   {
  //     organization_name: 'Midas-Care Pharma Pvt. Ltd',
  //     organization_id: 9,
  //   },
  // ];

  // Handle radio selection
  const handleAccessChange = (event) => {
    const value = event.target.value;
    setAccessType(value);

    // Automatically populate CMOs for "Access all CMOs"
    if (value === 'all') {
      handleChangeCMOList(
        'selectedCMOs',
        cmoList.filter((val) => val?.vendorPlant)
      );
    } else {
      handleChangeCMOList('selectedCMOs', []); // Clear CMOs for "Access select CMOs"
    }
  };

  const handleSelectionChange = (event, value) => {
    if (
      selectedCMOs.filter(
        (val) =>
          // val.organization_name === value[0].organization_name &&
          val.vendorPlant === value[0].vendorPlant
      ).length === 0
    ) {
      handleChangeCMOList('selectedCMOs', selectedCMOs.concat(value));
    } else {
      handleChangeCMOList(
        'selectedCMOs',
        selectedCMOs.filter(
          (val) =>
            !(
              // val.organization_name === value[0].organization_name &&
              (val.vendorPlant === value[0].vendorPlant)
            )
        )
      );
    }
  };

  return (
    <Box>
      {/* Heading */}
      <Typography variant='h6' gutterBottom>
        Associate CMOs <span style={{ color: 'red' }}>*</span>
      </Typography>

      {/* Radio Buttons */}
      <FormControl component='fieldset' style={{ marginBottom: '1rem' }}>
        <RadioGroup value={accessType} onChange={handleAccessChange}>
          <FormControlLabel
            value='all'
            control={<Radio color='primary' />}
            label={`Access all CMOs (${
              cmoList.filter((val) => val?.vendorPlant).length
            } CMOs)`}
          />
          <FormControlLabel
            value='select'
            control={<Radio color='primary' />}
            label='Access select CMOs'
          />
        </RadioGroup>
      </FormControl>

      {/* Autocomplete with checkbox for multiple selections */}
      {accessType === 'select' && (
        <Autocomplete
          multiple
          id='checkboxes-tags-demo'
          options={cmoList.filter((val) => val?.vendorPlant)}
          disableCloseOnSelect
          value={[]}
          onChange={handleSelectionChange}
          getOptionLabel={(option) =>
            `${option.organization_name} - ${option.vendorPlant}`
          }
          renderOption={(option) => {
            let selected =
              selectedCMOs.filter(
                (val) =>
                  // val.organization_name === option.organization_name &&
                  val.vendorPlant === option.vendorPlant
              ).length > 0;
            return (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.organization_name} - {option.vendorPlant}
              </React.Fragment>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon />,
              }}
              // label='Select Options'
              placeholder='Start typing to search and select'
            />
          )}
        />
      )}

      {/* Display selected CMOs */}
      <Box mt={2}>
        <Typography variant='body1' style={{ marginBottom: '0.5rem' }}>
          Your selections
        </Typography>
        <Box
          style={{
            border: errors?.associateCMOs
              ? '1px solid #f44336'
              : '1px solid #ccc',
            borderRadius: '4px',
            padding: '1rem',
            maxHeight: '150px',
            overflowY: 'auto', // Enable vertical scroll when selections exceed box height
            display: 'flex',
            flexDirection: 'column', // Stack items vertically
          }}
        >
          {selectedCMOs.length > 0 ? (
            cmoList
              .filter((itemA) =>
                selectedCMOs.some(
                  (itemB) => itemA.vendorPlant === itemB.vendorPlant
                )
              )
              .map((val, index) => (
                <Typography
                  key={index}
                  variant='body2'
                  style={{ fontSize: '14px' }}
                >
                  {val.organization_name} - {val.vendorPlant}
                </Typography>
              ))
          ) : (
            <Typography variant='body2' style={{ color: '#aaa' }}>
              No selections available.
            </Typography>
          )}
        </Box>
        {errors?.associateCMOs && (
          <Typography variant='caption' style={{ color: '#f44336' }}>
            {errors?.associateCMOs}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AssociateCMOs;
