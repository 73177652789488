import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  Typography,
  Divider,
  Grid,
  AppBar,
  Toolbar,
  makeStyles,
  CircularProgress,
  Dialog,
} from '@material-ui/core';
import {
  appUrl,
  associateCMOsRoles,
  associateWarehouseRoles,
} from '../../../../../utils/config';
import AssociateCMOs from '../../common/inviteusers/AssociateCMOs';
import AssociateWarehouse from '../../common/inviteusers/AssociateWarehouse';
import CloseIcon from '@material-ui/icons/Close';
import StyledButton from '../../common/models/Button';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import BackDropLoading from '../../common/models/BackDrop';
import {
  DESTROY_BACKDROP,
  OPEN_BACKDROP,
} from '../../../containers/actions/types';
import StyledDialog from '../../../../common/models/Dialog';
import jwt_decode from 'jwt-decode';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    color: 'black',
  },

  closeText: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    paddingLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      fontSize: '1.7rem',
    },
  },
  grid: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '2px solid red',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
    },
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'start',
      padding: '0.5rem',
    },
  },
}));

const UserDetailsEditPage = ({ handleClose, userDetails }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [selectedCMOs, setSelectedCMOs] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [userData, setUserData] = useState(null);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setFieldErrors] = useState(null); //Field error
  const { authToken } = localStorage;
  var token = authToken;
  var decoded = jwt_decode(token);

  useEffect(() => {
    // setUserData({
    //   collaboratorRoleID: 12,
    //   lastUpdatedBy: 'aniketh.d@collabrix.co',
    //   userActive: true,
    //   warehouseDetailsList: [
    //     {
    //       userAssociationCollabrixID: 11,
    //       associationFlag: true,
    //       warehouseName: 'CWH-Chennai',
    //       warehouseCode: 'L523',
    //     },
    //     {
    //       userAssociationCollabrixID: 22,
    //       associationFlag: true,
    //       warehouseName: 'CWH-Zirakpur',
    //       warehouseCode: 'L701',
    //     },
    //   ],
    //   senderLegalName: 'O=Wockhardt,L=Mumbai,C=IN',
    //   brandOwnerMosymphonyID: 1,
    //   saasUserId: 1,
    //   cmoOrgList: [
    //     {
    //       userAssociationCollabrixID: 33,
    //       vendorPlant: 'L999',
    //       receiverLegalName: 'O=Gland Pharma,L=London,C=GB',
    //       associationFlag: true,
    //       vendorMosymphonyID: 2,
    //     },
    //     {
    //       userAssociationCollabrixID: 44,
    //       vendorPlant: 'L459',
    //       receiverLegalName: 'O=Gland Pharma,L=London,C=GB',
    //       associationFlag: true,
    //       vendorMosymphonyID: 2,
    //     },
    //   ],
    // });

    // setSelectedWarehouses([
    //   {
    //     userAssociationCollabrixID: 11,
    //     associationFlag: true,
    //     warehouseName: 'CWH-Chennai',
    //     warehouseCode: 'L523',
    //   },
    //   {
    //     userAssociationCollabrixID: 22,
    //     associationFlag: true,
    //     warehouseName: 'CWH-Zirakpur',
    //     warehouseCode: 'L701',
    //   },
    // ]);
    // setSelectedCMOs([
    //   {
    //     userAssociationCollabrixID: 12,
    //     vendorPlant: 'L728',
    //     vendorMosymphonyID: 9,
    //     receiverLegalName: '...',
    //     associationFlag: true,
    //   },
    //   {
    //     userAssociationCollabrixID: 12,
    //     vendorPlant: 'L749',
    //     vendorMosymphonyID: 10,
    //     receiverLegalName: '...',
    //     associationFlag: true,
    //   },
    // ]);

    axios
      .get(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchPlantWarehouseUserAssociation/${userDetails.saasUserId}`
      )
      .then((res) => {
        if (res.data.status === true) {
          setLoading(false);
          setUserData(res.data.data);
          setSelectedWarehouses(res.data.data.warehouseDetailsList);
          setSelectedCMOs(res.data.data.cmoOrgList);
        } else {
          setLoading(false);
          setError(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        console.log(err);
      });
  }, [userDetails.saasUserId]);

  const handleChangeWarehouseOrCMOList = (name, val) => {
    setFieldErrors(null);
    if (name === 'selectedCMOs') {
      setSelectedCMOs(val);
    }
    if (name === 'selectedWarehouses') {
      setSelectedWarehouses(val);
    }
  };

  const handleSaveUserDetails = () => {
    setFieldErrors(null);
    let associatedWarehouses = [];
    let associatedCMOs = [];

    if (
      userData.warehouseDetailsList &&
      Array.isArray(userData.warehouseDetailsList)
    ) {
      userData.warehouseDetailsList.forEach((data) => {
        const exists = selectedWarehouses.some(
          (val) =>
            val.warehouseName === data.warehouseName &&
            val.warehouseCode === data.warehouseCode
        );

        if (!exists) {
          associatedWarehouses.push({
            ...data,
            associationFlag: false, // Mark as not associated
          });
        } else {
          associatedWarehouses.push(data);
        }
      });

      selectedWarehouses.forEach((data) => {
        const exists = userData.warehouseDetailsList.some(
          (val) =>
            val.warehouseName === data.warehouseName &&
            val.warehouseCode === data.warehouseCode
        );
        if (!exists) {
          associatedWarehouses.push(data);
        }
      });
    }

    if (userData.cmoOrgList && Array.isArray(userData.cmoOrgList)) {
      userData.cmoOrgList.forEach((data) => {
        const exists = selectedCMOs.some(
          (val) => val.vendorPlant === data.vendorPlant
        );

        if (!exists) {
          associatedCMOs.push({
            ...data,
            associationFlag: false, // Mark as not associated
          });
        } else {
          associatedCMOs.push(data);
        }
      });

      selectedCMOs.forEach((data) => {
        const exists = userData.cmoOrgList.some(
          (val) => val.vendorPlant === data.vendorPlant
        );

        if (!exists) {
          associatedCMOs.push(data);
        }
      });
    }

    // const newWarehouseList = selectedWarehouses.concat(removedWarehouses);
    // const newCMOsAssociates = selectedCMOs.concat(removedCMOsAssociates);

    let validateAssociatedCMOs =
      associatedCMOs.filter(
        (val) =>
          val.associationFlag === true || !val?.userAssociationCollabrixID
      ).length > 0;

    if (
      validateAssociatedCMOs ||
      associateWarehouseRoles.includes(userDetails?.roleId)
    ) {
      let data = {
        // ...userData,
        senderLegalName: null,
        saasUserID: userDetails.saasUserId,
        collaboratorRoleID: userDetails.roleId,
        brandOwnerMosymphonyID: decoded?.organization_id,
        lastUpdatedBy: decoded?.email_address,
        userActiveFlag: !userDetails.isDeactivated,
        warehouseDetailsList: associatedWarehouses,
        cmoOrgList: associatedCMOs,
      };
      console.log(data);
      dispatch({
        type: OPEN_BACKDROP,
        backdropText: 'Please Wait. Updating ....',
      });

      axios
        .post(
          `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/saveUserAssociations`,
          data
        )
        .then((res) => {
          if (res.data.status === true) {
            setSuccessOpen(true);
          } else {
            setError(true);
          }
          dispatch({
            type: DESTROY_BACKDROP,
          });
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          dispatch({
            type: DESTROY_BACKDROP,
          });
        });
    } else {
      setFieldErrors({
        associateCMOs: 'Please select Associate CMOs',
      });
    }
  };

  const handleCloseSuccess = () => {
    setSuccessOpen(!successOpen);
    if (successOpen) {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <BackDropLoading />
      <AppBar
        position='sticky'
        style={{
          backgroundColor: '#FFFFFF',
          color: 'black',
          boxShadow: 'none',
          borderBottom: '1px solid #B4B4B4',
        }}
      >
        <Toolbar>
          <Grid container className={classes.root}>
            <Grid item xs={2}></Grid>

            <Grid item xs={8} style={{ justifyItems: 'center' }}>
              <Typography variant='h4' className={classes.title}>
                Edit User Details
              </Typography>
            </Grid>
            <Grid item>
              <div className={classes.grid}>
                <Typography
                  variant='body2'
                  className={classes.closeText}
                  onClick={handleClose}
                >
                  Cancel & go back{' '}
                </Typography>
                <CloseIcon
                  fontSize='large'
                  color='error'
                  className={classes.icon}
                  onClick={handleClose}
                />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Horizontally center the CircularProgress
              alignItems: 'center', // Vertically center the CircularProgress
              height: '100px', // Optional: Set a height to vertically center the loader
            }}
          >
            <CircularProgress />
          </div>
        ) : error ? (
          <Grid
            container
            justifyContent='center'
            style={{
              padding: '1rem 0',
              display: 'inherit',
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                border: '1px solid #FF0000',
                backgroundColor: '#FFF0F0',
                opacity: '1',
                padding: '0.8rem',
                fontSize: '0.875rem',
                textAlign: 'center',
              }}
            >
              <Typography
                variant='h6'
                style={{
                  fontSize: '0.875rem',
                }}
              >
                Something went wrong. Please try again.
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            style={{ justifyContent: 'center', margin: '30px 0px' }}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>Collaborator Role</Typography>
                  <Typography>
                    {userDetails?.roleName ? userDetails.roleName : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>Email Address</Typography>
                  <Typography>
                    {userDetails?.emailAddress ? userDetails.emailAddress : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>
                    Full Name of the Person
                  </Typography>
                  <Typography>
                    {userDetails?.fullName ? userDetails.fullName : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>Mobile Number</Typography>
                  <Typography>
                    {userDetails?.phoneNumber ? userDetails.phoneNumber : '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ margin: '20px 0' }} />
              {associateCMOsRoles.includes(userDetails?.roleId) && (
                <AssociateCMOs
                  selectedCMOs={selectedCMOs}
                  handleChangeCMOList={handleChangeWarehouseOrCMOList}
                  errors={errors}
                />
              )}
              {associateWarehouseRoles.includes(userDetails?.roleId) && (
                <AssociateWarehouse
                  selectedWarehouses={selectedWarehouses}
                  handleChangeWarehouseOrCMOList={
                    handleChangeWarehouseOrCMOList
                  }
                />
              )}
              <Grid
                container
                justifyContent='center'
                spacing={2}
                style={{ margin: '30px 0px' }}
              >
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={4}
                  lg={6}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <StyledButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    size='medium'
                    onClick={handleSaveUserDetails}
                  >
                    Save changes
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Dialog open={successOpen} fullWidth>
        <StyledDialog
          icons={true}
          handleClose={handleCloseSuccess}
          title={'User Details Updated Successfully'}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default UserDetailsEditPage;
