import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import useStyles from '../../models/buttons/buttonStyles';
import UpdateScreen from '../batch/updateBatch';
// import ResolveDialog from '../../models/ResolveDialog';
import CheckedAndApproved from '../../models/checkedAndApproved';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import ResolveBatchAndQaDialog from './ResolveBatchAndQaDialog';
import { DialogContent, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import StyledButton from '../../models/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.5rem',
    // '&:hover': {
    //   borderBottom: '0.5px solid red',
    //   color: '#e36e39',
    // },
  },
  // closeText: {
  //   fontSize: '1rem',
  //   fontWeight: 500,
  //   display: 'flex',
  //   alignItems: 'center',
  //   cursor: 'pointer',
  //   padding: '1rem',
  //   '&:hover': {
  //     borderBottom: '0.5px solid red',
  //     color: '#e36e39',
  //   },
  // },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: '1rem',
    fontWeight: 500,
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: '#39811D',
  },
  cardContent: {
    padding: '2rem !important',
  },
  buttonGrid: {
    textAlign: 'center',
    padding: '0.5rem',
  },
  noButton: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #000000de',
    borderRadius: '0.5rem',
    padding: '0.3rem',
    width: '7.3rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #000000de',
    },
  },
  logoHeight: {
    height: 'inherit',
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const ResolveCqaAndEinvoiceUploadButton = (props) => {
  const classes = useStyles();
  const {
    schrockenDeliveryScheduleBatchID,
    schrockenDeliveryScheduleID,
    SASLBatchStatusID,
    statusDisplayName,
    // rejectedBy,
    // rejectionText,
    batchDashboard,
    qaStatusID,
    qaStatusText,
    qaReasonForChangeInReleaseDecision,
    qaReleaseDecisionBy,
    qaRejectionReason,
    qaStatusUpdatedBy,
  } = props;

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(!open);
  };

  const [openBatchAndQaDialog, setOpenBatchAndQaDialog] = React.useState(false);
  const [uploadEInvoiceFlag, setUploadEInvoiceFlag] = React.useState(false);

  const handleOpenBatchAndQaDialog = () => {
    setOpenBatchAndQaDialog(true);
  };
  const handleOpenBatchAndQaUpdate = (type) => {
    if (type === 'Upload_EInvoice') {
      setUploadEInvoiceFlag(true);
    } else {
      setUploadEInvoiceFlag(false);
    }
    setOpen(!open);
    setOpenBatchAndQaDialog(false);
  };

  const handleCloseBatchAndQaDialog = () => {
    setOpen(false);
    setOpenBatchAndQaDialog(false);
  };

  return (
    <React.Fragment>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.button}
        // onClick={handleClickRecieved}
      >
        <div
          className={classes.logo}
          onClick={() => {
            handleOpenBatchAndQaDialog();
          }}
        >
          <AutorenewIcon fontSize='small' className={classes.logoHeight} />
          &nbsp;
          <Typography variant='body1'>Update</Typography>
        </div>
      </Button>

      <UpdateScreen
        open={open}
        handleClose={handleClose}
        schrockenDeliveryScheduleBatchID={schrockenDeliveryScheduleBatchID}
        schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
        SASLBatchStatusID={SASLBatchStatusID}
        statusDisplayName={statusDisplayName}
        batchDashboard={batchDashboard}
        qaStatusID={qaStatusID}
        qaStatusText={qaStatusText}
        uploadEInvoiceFlag={uploadEInvoiceFlag}
      />

      <Dialog open={openBatchAndQaDialog} fullWidth>
        <DialogContent
          classes={{
            root: {
              padding: '2rem !important',
            },
          }}
        >
          <Grid container justify='flex-end'>
            <Grid item xs={12} sm={9} md={9}>
              <Typography
                variant='h5'
                style={{
                  textAlign: 'center',
                  padding: '0.5rem',
                  fontWeight: 500,
                  fontSize: '1.5rem',
                }}
              >
                Choose Action
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ marginLeft: '-1.3rem' }}>
              <Typography
                variant='body2'
                className={classes.closeText}
                onClick={handleCloseBatchAndQaDialog}
              >
                Close <CloseIcon style={{ color: 'red' }} fontSize='large' />
              </Typography>
            </Grid>
          </Grid>
          {/* 
          <Typography variant='body2' className={classes.text}>
            Issue Comment
          </Typography> */}

          <form className={classes.root} noValidate autoComplete='off'>
            <Typography
              style={{
                paddingTop: '1rem',
                paddingBottom: '1rem',
                fontWeight: 500,
              }}
            >
              CoA Issue Comment
            </Typography>
            <TextField
              id='outlined-multiline-static'
              label='Issue Comment.'
              multiline
              rows={6}
              value={
                qaReasonForChangeInReleaseDecision
                  ? qaReasonForChangeInReleaseDecision
                  : qaRejectionReason
                  ? qaRejectionReason
                  : ''
              }
              // onChange={handleText}
              // width='100%'
              variant='outlined'
              style={{
                width: '100%',
              }}
              disabled={true}
            />
            <Grid style={{ display: 'flex', paddingTop: '1rem' }}>
              <Typography
                variant='body2'
                style={{ padding: '0.5rem', fontWeight: 500 }}
              >
                Rejected By
              </Typography>
              <CheckedAndApproved
                name={
                  qaReleaseDecisionBy
                    ? qaReleaseDecisionBy
                    : qaStatusUpdatedBy
                    ? qaStatusUpdatedBy
                    : ''
                }
              />
            </Grid>
          </form>

          <Grid
            item
            xs={12}
            className={classes.buttonGrid}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '2rem',
            }}
          >
            <StyledButton
              style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
              onClick={() => handleOpenBatchAndQaUpdate('CoA_Resolve')}
              endIcon={
                <ArrowForwardIosIcon
                  style={{ fontSize: props.size === 'small' ? 12 : 15 }}
                />
              }
            >
              Resolve CoA Issue
            </StyledButton>
            <StyledButton
              style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
              onClick={() => handleOpenBatchAndQaUpdate('Upload_EInvoice')}
              endIcon={
                <ArrowForwardIosIcon
                  style={{ fontSize: props.size === 'small' ? 12 : 15 }}
                />
              }
            >
              Upload E-Invoice
            </StyledButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ResolveCqaAndEinvoiceUploadButton;
