import React from 'react';
import Updatebutton from './updateButton';
import ResolveButton from './ResolveButton';
import ApproveButton from './ApproveButton';
import ResolveCqaAndEinvoiceUploadButton from './ResolveCqaAndEinvoiceUploadButton';

const StatusManager = (
  schrockenDeliveryScheduleID,
  schrockenDeliveryScheduleBatchID,
  SASLBatchStatusID,
  statusDisplayName,
  rejectionText,
  rejectedBy,
  // access control
  featuresAccess,
  batchDashboard,
  qualityApprovalFlowFlag,
  qaStatusID,
  qaStatusText,
  qaReasonForChangeInReleaseDecision,
  qaReleaseDecisionBy,
  qaRejectionReason,
  qaStatusUpdatedBy,
  vendorMosymphonyID,
  brandOwnerMosymphonyID,
  sendReminderForCOAUpload,
  scheduleLineItemNumber,
  scheduleAgreementNumber,
  vendorBatchNumber,
  fgMaterialCode,
  fgMaterialName
) => {
  const { collabType } = localStorage;

  // Quality Manager
  // Quality Head
  if (
    collabType === 'Pharma Co' &&
    qualityApprovalFlowFlag === true &&
    (featuresAccess?.approveQA || featuresAccess?.reverseQA)
  ) {
    //CQA flow is true and user has the access to approve or reject CoA
    switch (String(qaStatusID)) {
      case '1': //Pending
        if (
          featuresAccess?.approveQA &&
          [6, 7, 8, 13, 19, 20].includes(SASLBatchStatusID)
        )
          // When approveQA feature is true and the SASL Batch status are in Awaiting GRN or GRN Generated or Dispatch or Resubmit Record or E-Invoice uploaded or E-Way Bill uploaded statuses.
          return (
            <ApproveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
              qaApproval
            />
          );
        break;
      case '2': // Approved
      case '3': // Rejected
        if (
          featuresAccess?.reverseQA &&
          [6, 7, 13, 19].includes(SASLBatchStatusID)
        )
          // When reverseQA feature is true and the SASL Batch status are in Awaiting GRN or GRN Generated or Dispatch or Resubmit Record or E-Invoice uploaded statuses.
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
              qaApproval
            />
          );
        break;
      default:
        return null;
    }
  } else if (collabType === 'Pharma Co' && qualityApprovalFlowFlag === true) {
    // Brand owner batch update/approve button when CQA flow is true
    switch (SASLBatchStatusID) {
      case 6: // Awaiting GRN
        if (
          (featuresAccess?.approveFGGRN && SASLBatchStatusID === 6) ||
          (SASLBatchStatusID === 14 && featuresAccess?.approveFGQCQA)
        )
          //Approve Button to Approve GRN
          return (
            <ApproveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
            />
          );
        break;
      case 7: //GRN Generated
        if (featuresAccess?.approveFGGRN && !qaStatusID)
          //send reminder button
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
              sendReminderForCoA={{
                vendorMosymphonyID: vendorMosymphonyID,
                brandOwnerMosymphonyID: brandOwnerMosymphonyID,
                sendReminderForCOAUpload: sendReminderForCOAUpload
                  ? sendReminderForCOAUpload
                  : false,
                scheduleLineItemNumber: scheduleLineItemNumber,
                scheduleAgreementNumber: scheduleAgreementNumber,
                vendorBatchNumber: vendorBatchNumber,
                fgMaterialCode: fgMaterialCode,
                fgMaterialName: fgMaterialName,
                schrockenDeliveryScheduleBatchID:
                  schrockenDeliveryScheduleBatchID,
              }}
            />
          );
        // User enters else block to Upload E-Invoice file.
        else if (qaStatusID && featuresAccess?.updateToEinvoice)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
            />
          );
        break;
      case 8: // Dispatch
        if (featuresAccess?.approveFGGRN)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
            />
          );
        break;
      case 19: //E-Invoice Uploaded
        //User gets Update button to upload E-Way Bill file or to update status to Goods Received
        if (featuresAccess?.updateToEwayBill)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
            />
          );
        break;
      default:
        return null;
    }
  } else if (collabType === 'Pharma Co') {
    // Brand owner batch update/approve button access in normal flow
    switch (SASLBatchStatusID) {
      case 6: // Awaiting GRN
      case 14: // Awaiting QC/QA Approval
        if (
          (featuresAccess?.approveFGGRN && SASLBatchStatusID === 6) ||
          (SASLBatchStatusID === 14 && featuresAccess?.approveFGQCQA)
        )
          return (
            <ApproveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
            />
          );
        break;
      case 7:
      case 8:
      case 18: //BD Generated
        if (featuresAccess?.approveFGGRN)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
            />
          );
        break;
      default:
        return null;
    }
  } else if (collabType !== 'Pharma Co' && qualityApprovalFlowFlag === true) {
    // CMO batch update/resolve button access when CQA flow is true
    switch (SASLBatchStatusID) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5: //QC Release
      case 18: //BD generated
        if (featuresAccess?.updateFGBatch)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
            />
          );
        break;
      case 6: //Awaiting GRN
        if (featuresAccess?.updateFGBatch && !qaStatusID)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
            />
          );
        else if (featuresAccess?.updateFGBatch && qaStatusID === '3')
          return (
            <ResolveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              rejectionText={rejectionText}
              rejectedBy={rejectedBy}
              batchDashboard={batchDashboard}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
              qaReasonForChangeInReleaseDecision={
                qaReasonForChangeInReleaseDecision
              }
              qaReleaseDecisionBy={qaReleaseDecisionBy}
              qaRejectionReason={qaRejectionReason}
              qaStatusUpdatedBy={qaStatusUpdatedBy}
            />
          );
        break;
      case 7: //GRN Generated
        if (featuresAccess?.updateFGBatch && qaStatusID === '3')
          return (
            <ResolveCqaAndEinvoiceUploadButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              rejectionText={rejectionText}
              rejectedBy={rejectedBy}
              batchDashboard={batchDashboard}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
              qaReasonForChangeInReleaseDecision={
                qaReasonForChangeInReleaseDecision
              }
              qaReleaseDecisionBy={qaReleaseDecisionBy}
              qaRejectionReason={qaRejectionReason}
              qaStatusUpdatedBy={qaStatusUpdatedBy}
            />
          );
        else if (featuresAccess?.updateFGBatch && !qaStatusID) {
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
            />
          );
        } else if (
          qaStatusID &&
          featuresAccess?.updateFGBatch &&
          featuresAccess?.updateToEinvoice
        ) {
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
            />
          );
        }
        break;
      case 8: //Dispatch
        if (featuresAccess?.updateFGBatch && qaStatusID === '3')
          return (
            <ResolveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              rejectionText={rejectionText}
              rejectedBy={rejectedBy}
              batchDashboard={batchDashboard}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
              qaReasonForChangeInReleaseDecision={
                qaReasonForChangeInReleaseDecision
              }
              qaReleaseDecisionBy={qaReleaseDecisionBy}
              qaRejectionReason={qaRejectionReason}
              qaStatusUpdatedBy={qaStatusUpdatedBy}
            />
          );

        break;
      case 13: // Resubmit Record
        // case 15: // Repeat QC/QA
        if (featuresAccess?.updateFGBatch)
          return (
            <ResolveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              rejectionText={rejectionText}
              rejectedBy={rejectedBy}
              batchDashboard={batchDashboard}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
              qaReasonForChangeInReleaseDecision={
                qaReasonForChangeInReleaseDecision
              }
              qaReleaseDecisionBy={qaReleaseDecisionBy}
              qaRejectionReason={qaRejectionReason}
              qaStatusUpdatedBy={qaStatusUpdatedBy}
            />
          );
        break;
      case 19: //E-Invoice Uploaded
      case 20: //E-Way Bill Uploaded
        if (featuresAccess?.updateFGBatch && qaStatusID === '3')
          return (
            <ResolveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              rejectionText={rejectionText}
              rejectedBy={rejectedBy}
              batchDashboard={batchDashboard}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
              buttonName={'Update and Resolve'}
              qaReasonForChangeInReleaseDecision={
                qaReasonForChangeInReleaseDecision
              }
              qaReleaseDecisionBy={qaReleaseDecisionBy}
              qaRejectionReason={qaRejectionReason}
              qaStatusUpdatedBy={qaStatusUpdatedBy}
            />
          );
        else if (
          featuresAccess?.updateFGBatch === true &&
          SASLBatchStatusID === 20
        )
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
              qaStatusID={qaStatusID}
              qaStatusText={qaStatusText}
            />
          );
        break;
      default:
        return null;
    }
  } else {
    // CMO batch update/resolve button access in normal flow
    switch (SASLBatchStatusID) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 7:
      case 18: //BD generated
        if (featuresAccess?.updateFGBatch)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
            />
          );
        break;
      case 13: // Resubmit Record
      case 15: // Repeat QC/QA
        if (featuresAccess?.updateFGBatch)
          return (
            <ResolveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              rejectionText={rejectionText}
              rejectedBy={rejectedBy}
              batchDashboard={batchDashboard}
            />
          );
        break;
      default:
        return null;
    }
  }
};

export default StatusManager;
