import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import jwt_decode from 'jwt-decode';
import Dialog from '@material-ui/core/Dialog';
import DeactivateConfirmation from '../../common/userDeactivation';
import StyledDialog from '../../../../common/models/Dialog';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  IconButton,
  Typography,
  TablePagination,
  Select,
  TextField,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import ReplayIcon from '@material-ui/icons/Replay';
import SearchIcon from '@material-ui/icons/Search';

import orderBy from 'lodash/orderBy';

import { withStyles } from '@material-ui/core/styles';

import {
  cmoUsersDetails,
  manageuserSource,
} from '../../../containers/actions/pharmaCo/manageUsersActions';
import { tableFilter } from '../../common/functionalComponents/TableFilter';
import LightTooltip from '../../common/models/buttons/ToolTip';

const tableDataFilter = tableFilter;

const header = [
  // { name: "Member ID", id: "saasUserId" },
  // { name: "Status", id: "batchSize" },
  { name: 'Organization Name', id: 'organizationName' },
  // { name: "Organization Code", id: "organizationNumber" },
  { name: 'Collaborator Type', id: 'collaboratorType' },
  { name: 'Role', id: 'roleName' },
  { name: 'Username', id: 'emailAddress' },
];

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '2rem',
  },
  table: {
    minWidth: 700,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  resize: {
    fontSize: '0.85rem',
  },
  fontColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.85rem',
  },
  styledBtn: {
    textTransform: 'none',
    '&:hover': {
      color: 'rgb(158, 77, 39)',
    },
    // borderRadius: '2rem',
  },
});

class UserDetails extends Component {
  state = {
    members: [],
    page: 0,
    rowsPerPage: 5,
    header,
    displayFilter: false,
    query: '',
    columnToQuery: 'organizationName',
    columnToSort: '',
    sortDirection: 'desc',
    fieldSelected: false,
    openConfirmation: false,
    deactivateUserData: {},
    openFinalConfirmation: false,
  };

  componentDidMount() {
    this.getUserDetails();
  }
  componentWillUnmount() {
    if (manageuserSource) {
      manageuserSource.cancel('leaving page');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.members.usersData) {
      // this.setState({ members: nextProps.members.usersData });
      const members = nextProps.members.usersData.sort(
        (a, b) => b.inviteStatus - a.inviteStatus
      );
      this.setState({ members });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      fieldSelected: false,
    });

    if (this.state.columnToQuery === 'none') {
      if (event.target.name === 'query') {
        this.setState({ fieldSelected: true });
      }
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  confirmDeactivation = (email, sassId, roleId) => {
    this.setState({
      openConfirmation: true,
      deactivateUserData: {
        ...this.state.deactivateUserData,
        email: email,
        id: sassId,
        roleId: roleId,
      },
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleCloseConfirmation = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  getUserDetails = () => {
    const data = {
      ecosystemId: localStorage.ecosystemId,
      organizationName: localStorage.organizationName,
    };
    this.props.cmoUsersDetails(data);
  };

  // show search fields
  showFilter = () => {
    this.setState({
      displayFilter: !this.state.displayFilter,
      columnToQuery: 'organizationName',
      query: '',
    });
  };

  render() {
    const { classes } = this.props;
    const { members, page, rowsPerPage, openFinalConfirmation } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, members.length - page * rowsPerPage);
    const { roleName, organizationName, authToken } = localStorage;
    var token = authToken;
    var decoded = jwt_decode(token);
    // console.log(decoded)
    const useFunc = (data) => {
      this.setState({
        openFinalConfirmation: data,
      });
    };
    const handleCloseFinalConfirmation = () => {
      this.setState({
        openFinalConfirmation: false,
      });
      this.getUserDetails();
    };
    const membersData = orderBy(
      this.state.query
        ? members.filter((data) =>
            tableDataFilter(
              data,
              this.state.columnToQuery,
              this.state.query.toLowerCase(),
              this.state.header
            )
          )
        : members,
      this.state.columnToSort,
      this.state.sortDirection
    );

    return (
      <Paper className={classes.root}>
        <Toolbar className={classes.spaceBetween}>
          <Typography variant='h6'>User Details</Typography>

          {this.state.displayFilter && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Select
                native
                style={{
                  marginLeft: '1em',
                  marginRight: '1.5rem',
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontSize: '0.85rem',
                }}
                value={this.state.columnToQuery}
                name='columnToQuery'
                onChange={this.handleChange}
                error={this.state.fieldSelected}
              >
                {/* <option value="all" className={classes.fontColor}>
                  All
                </option> */}
                {header.map((ele, x) => (
                  <option
                    key={`opt-${x}`}
                    value={ele.id}
                    className={classes.fontColor}
                  >
                    {ele.name}
                  </option>
                ))}
              </Select>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />

                <TextField
                  name='query'
                  placeholder='Search... '
                  value={this.state.query}
                  onChange={this.handleChange}
                  // className={classes.fontColor}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </div>
            </div>
          )}

          <div>
            <IconButton aria-label='Filter list' onClick={this.showFilter}>
              <FilterListIcon />
            </IconButton>
            <IconButton onClick={this.getUserDetails}>
              <ReplayIcon />
            </IconButton>
          </div>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Member ID</TableCell>
              <TableCell align='right'>Status</TableCell>
              <TableCell align='right'>Organization Name</TableCell>
              <TableCell align='right'>Organization Code</TableCell>
              <TableCell align='right'>Collaborator Type</TableCell>
              <TableCell align='right'>Role</TableCell>
              <TableCell align='right'>Username</TableCell>
              {roleName === 'Admin' && (
                <TableCell align='right'>Action</TableCell>
              )}

              {/* <TableCell align="right">{null}</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {membersData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((member, i) => (
                <TableRow key={i} className={classes.tableRowHover}>
                  <TableCell align='left'>
                    {member.inviteStatus === 1 ? member.saasUserId : 'NA'}
                  </TableCell>
                  <TableCell align='right'>
                    {member.isDeactivated === true
                      ? 'Deactivated'
                      : member.inviteStatus === 1
                      ? 'Active'
                      : 'Inactive'}
                  </TableCell>
                  <TableCell align='right'>{member.organizationName}</TableCell>
                  <TableCell align='right'>
                    {member.inviteStatus === 1
                      ? member.organizationNumber
                      : 'NA'}
                  </TableCell>
                  <TableCell align='right'>{member.collaboratorType}</TableCell>
                  <TableCell align='right'>{member.roleName}</TableCell>
                  <TableCell align='right'>{member.emailAddress}</TableCell>
                  {roleName === 'Admin' &&
                    member.organizationName === organizationName &&
                    decoded.saas_user_id !== member.saasUserId && (
                      <TableCell align='right'>
                        <IconButton
                          variant='contained'
                          color='primary'
                          disableRipple
                          disableFocusRipple
                          className={classes.styledBtn}
                          onClick={
                            member.inviteStatus === 1 &&
                            member.isDeactivated === false
                              ? () =>
                                  this.confirmDeactivation(
                                    member.emailAddress,
                                    member.saasUserId,
                                    member.roleId
                                  )
                              : true
                          }
                        >
                          {member.inviteStatus === 1 &&
                            member.isDeactivated === false && (
                              <LightTooltip
                                title='Deactivate'
                                placement='right-start'
                                arrow
                              >
                                <CancelIcon />
                              </LightTooltip>
                            )}
                        </IconButton>
                      </TableCell>
                    )}
                  {roleName === 'Admin' &&
                    (member.organizationName !== organizationName ||
                      decoded.saas_user_id === member.saasUserId) && (
                      <TableCell></TableCell>
                    )}

                  {/* <TableCell align="left">
                    <Typography variant="caption" color="secondary">
                      View Profile &gt;
                    </Typography>
                  </TableCell> */}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component='div'
          count={members.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <Dialog
          open={this.state.openConfirmation}
          onClose={this.handleCloseConfirmation}
          fullWidth
        >
          <DeactivateConfirmation
            handleConfirmation={useFunc}
            deactivateUserData={this.state.deactivateUserData}
            onClose={this.handleCloseConfirmation}
          />
        </Dialog>

        <Dialog
          open={openFinalConfirmation}
          onClose={handleCloseFinalConfirmation}
          fullWidth
        >
          <StyledDialog
            icons={true}
            handleClose={handleCloseFinalConfirmation}
            title='User has been deactivated'
            // subtitle='Your record has been added in queue for GRN generation.'
          />
        </Dialog>
      </Paper>
    );
  }
}

UserDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  members: state.pcmp.pharmaCo.manageUsers,
});

export default withStyles(styles)(
  connect(mapStateToProps, { cmoUsersDetails })(withRouter(UserDetails))
);
